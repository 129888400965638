import { clsx } from '@wonderful/wwc/dist/src/helpers/clsx';
import DOMPurify from 'isomorphic-dompurify';
import { useState } from 'react';

import BuilderEditingHelper from '@common/BuilderEditingHelper/BuilderEditingHelper';
import BuilderImage from '@common/BuilderImage/BuilderImage';
import Section from '@design/Section/Section';

import { HeadingBlockProps } from './HeadingBlock.types';
import styles from './HeadingBlock.module.scss';

const HeadingBlock = ({ header, subheader, image }: HeadingBlockProps) => {
  const isVisible = !image?.src?.length && !header && !subheader;

  const [isInViewport, setIsInViewport] = useState(false);

  const onEnterViewport = () => {
    setIsInViewport(true);
  };

  return (
    // @TODO: Source to load
    <Section
      className={clsx('container', isInViewport ? styles.inViewport : '')}
      onEnterViewport={onEnterViewport}
      data-testid="headingBlock"
    >
      <BuilderEditingHelper componentName="HeadingBlock" visible={isVisible} />
      <div className="row">
        <div className="col-12">
          <div className={styles.headingBlockContainer}>
            {image && image.src && (
              <div className={styles.imageContainer}>
                <BuilderImage
                  imageSrc={image.src}
                  mobileWidth={'220px'}
                  alt={image?.altText}
                  data-testid="headingImage"
                />
              </div>
            )}
            <div className={styles.copyContainer}>
              {header ? <h1 className={styles.header}>{header}</h1> : null}
              {subheader ? (
                <div
                  data-testid="subheader"
                  className={styles.subheader}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(subheader),
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default HeadingBlock;
